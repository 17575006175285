import React, {useEffect} from 'react';

import { faFacebookF,faYoutube,faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCoffee,
    faAddressBook,
    faAirFreshener,
    faFacebook,
    faAmbulance,
    faAtom,
    faBus,
    faCoins,
    faDice,
  } from '@fortawesome/free-solid-svg-icons'
  
const Footer = () => {
  useEffect(() => {

    document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
  })
  
    
  return(
    <>
    <footer className="footer-standard-dark bg-extra-dark-gray padding-40px-top"> 
<div className="footer-widget-area">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-md-6 widget md-margin-30px-bottom text-center text-md-left">
                <div className="widget-title alt-font text-light-gray text-uppercase margin-10px-bottom font-weight-600">Company</div>
                <ul className="list-unstyled">
                    <li><a className="text-small text-light-gray" href="/Company">Company</a></li>
                    <li><a className="text-small text-light-gray" href="/VerumDezyne">Verum Dezyne</a></li>
                    <li><a className="text-small text-light-gray" href="/Partners">Partners </a></li>
                    <li><a className="text-small text-light-gray" href="/Academia">Academia </a></li>
                    <li><a className="text-small text-light-gray" href="/News">News</a></li>
                </ul>
            </div>
            <div className="col-lg-3 col-md-6 widget md-margin-30px-bottom text-center text-md-left">
                <div className="widget-title alt-font text-light-gray text-uppercase margin-10px-bottom font-weight-600">Quick Links</div>
                <ul className="list-unstyled">
                    <li><a className="text-small text-light-gray" href="/Support">Support</a></li>
                    <li><a className="text-small text-light-gray" href="/Contact">Contact</a></li>
                    <li><a className="text-small text-light-gray" href="/Privacy">Privacy Policy</a></li>

                </ul>
            </div>
            <div className="col-lg-3 col-md-6 widget md-margin-30px-bottom text-center text-md-left">
                <div className="widget-title alt-font text-light-gray text-uppercase margin-10px-bottom font-weight-600">CONTACT INFO</div>
                <p className="text-small text-light-gray mb-0 font-weight-700">Verum Software Tools B.V.</p>
                <p className="text-small text-light-gray">Automotive Campus 30<br/>5708 JZ Helmond<br/>Netherlands</p>
            </div>
            <div className="col-lg-3 col-md-6 widget sm-margin-30px-bottom text-center text-md-left">
                <div className="widget-title alt-font text-light-gray text-uppercase margin-10px-bottom font-weight-600">Follow us on</div>
                <div className="elements-social social-icon-style-3">
                <ul className="small-icon">
                                    <li><a className="linkedin" href="https://www.linkedin.com/company/verum-software-technologies-bv/com" target="_blank">  <FontAwesomeIcon icon={faLinkedinIn}  />    </a></li>
                                    <li><a className="youtube" href="https://www.youtube.com/channel/UCC5UV3ik_fYjZkgMYFSsQmw" target="_blank"> <FontAwesomeIcon icon={faYoutube}  /></a></li>
                                    <li><a className="facebook" href="https://www.facebook.com/Verum-Software-108943698040839   " target="_blank"> <FontAwesomeIcon icon={faFacebookF}  /></a></li>
                                </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="text-center padding-25px-tb">
    <div className="container">
        <div className="row">
            <div className="col-md-6 text-md-left text-small text-center text-light-gray">2024 verum All rights reserved.</div>
            <div className="col-md-6 text-md-right text-small text-center text-light-gray">Created by <a href="http://elephantdreamz.com/" className="text-light-gray" target="_blank">ElephantDreamz</a></div>
        </div>
    </div>
</div>
</footer>
    </>
  );  
}
export default Footer;




