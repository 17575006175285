import React, { useState, useEffect } from 'react';
import './bootstrap.min.css';
import './style.css';
import './responsive.css';
import logo from '../components/logo.png';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowDown19, faBackward, faChevronDown, faUndo } from '@fortawesome/free-solid-svg-icons';

function Nav() {
    const [showMenu, setShowMenu] = useState(false);
    const [showVerumDropdown, setShowVerumDropdown] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showDropdownMenu, setShowDropdownMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const toggleVerumDropdown = () => {
        if (!isMobile) {
            setShowVerumDropdown(true);
        }
    };

    const toggleVerumDropdownFalse = () => {
        if (!isMobile) {
            setShowVerumDropdown(false);
        }
    };

    const updateMedia = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        updateMedia();
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);
    const handleMobileClick = () => {
        if (isMobile) {
            setShowDropdownMenu(!showDropdownMenu);
        }
    };
    
    return (
        <header>
            <nav className="navbar navbar-default bootsnav navbar-top header-light background-transparent nav-box-width navbar-expand-lg">
                <div className="container nav-header-container">
                    <div className="col-auto">
                        <a href="/" title="Verum" className="logo">
                            <img src={logo} alt="Verum" className="logo-dark default" />
                            <img src={logo} alt="Verum" className="logo-light" />
                        </a>
                    </div>
                    <div className="col accordion-menu pr-0 pr-md-3 no-padding-right">
                        <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar-collapse-toggle-1" onClick={toggleMenu}>
                            <span className="sr-only">toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <div className={`navbar-collapse collapse justify-content-end ${showMenu ? 'show' : ''}`} id="navbar-collapse-toggle-1">
                            <ul id="accordion" className="nav navbar-nav no-margin text-normal" data-in="fadeIn" data-out="fadeOut">
                                <li className={`dropdown simple-dropdown`}     onMouseEnter={!isMobile ? toggleVerumDropdown : null}
    onMouseLeave={!isMobile ? toggleVerumDropdownFalse : null}
    onClick={isMobile ? handleMobileClick : null}>
                                    <a href="#">Verum</a><i className="fas fa-angle-down dropdown-toggle" data-toggle="dropdown" aria-hidden="true"><FontAwesomeIcon icon={faChevronDown}  />  </i>
                                    {showDropdownMenu && (
                            <ul id="accordion" className=" no-margin text-normal" data-in="fadeIn" data-out="fadeOut">
                            <li><a href="/company">About Verum</a></li>
                                <li><a href="/community">Community</a></li>
                                <li><a className="dropdown-toggle" href="/partners">Partners</a></li>
                                <li><a href="/academia">Academia</a></li>
                                <li><a href="/News">News</a></li>
                                    </ul>    )}

                                    <ul className={`dropdown-menu ${showVerumDropdown ? 'show' : ''}`} role="menu">
                                        <li><a className="dropdown-toggle" href="/company">About Verum</a></li>
                                        <li><a className="dropdown-toggle" href="/community">Community</a></li>
                                        <li><a className="dropdown-toggle" href="/partners">Partners</a></li>
                                        <li><a className="dropdown-toggle" href="/academia">Academia</a></li>
                                        <li><a className="dropdown-toggle" href="/news">News</a></li>
                                    </ul>
                                </li>
                                <li><a href="/verumdezyne">Verum Dezyne</a></li>
                                <li><a href="/download">Download</a></li>
                                <li><a href="/support">Support</a></li>
                                <li><a href="https://forum.verum.com/">Forum</a></li>
                                <li><a href="/contact">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Nav;
